<template>
  <v-container
    id="my-contacts"
    fluid
    tag="section"
  >
    <v-overlay :value="isOverlayVisible">
      <v-progress-circular
        :size="70"
        :width="7"
        color="amber"
        indeterminate
        class="spinner"
      />
    </v-overlay>
    <base-v-component
      heading="Contacts"
      sub-heading="Contact List"
    />

    <template>
      <v-toolbar
        flat
        color="transparent"
      >
        <v-text-field
          v-model="searchText"
          v-debounce:300ms="_loadData"
          class="searchBox"
          label="Search"
        />
        <v-spacer />

        <v-btn
          v-if="isHubspotImportEnabled"
          color="primary"
          outlined
          dark
          class="mb-2 mr-8"
          @click="startHubSpotAuthentication"
        >
          Import Hubspot Contacts
        </v-btn>

        <google-contacts-import-dialog
          v-if="isGoogleImportEnabled"
          :groups="groups"
          @on-upload-start="onGoogleContactsImportStart"
          @on-success="onGoogleContactsImportSuccess"
          @on-error="onGoogleContactsImportError"
        />
        <import-contacts-dialog
          :groups="groups"
          @on-upload-start="importStart"
          @on-success="importSuccess"
          @on-error="importError"
        />
        <v-dialog
          v-model="dialog"
          max-width="500px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              dark
              class="mb-2 ml-4"
              v-bind="attrs"
              v-on="on"
            >
              Add Contact
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-text-field
                      v-model="editedItem.firstName"
                      required
                      label="First Name *"
                      :error="
                        !(
                          editedItem.firstName && editedItem.firstName.length
                        ) && isFormDirty
                      "
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-text-field
                      v-model="editedItem.lastName"
                      required
                      label="Last Name *"
                      :error="
                        !(editedItem.lastName && editedItem.lastName.length) &&
                          isFormDirty
                      "
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-text-field
                      v-model="editedItem.cellNumber"
                      v-mask="'(###) ###-####'"
                      placeholder="(___) ___-____"
                      required
                      label="Cell Number *"
                      :error="
                        !(
                          editedItem.cellNumber && editedItem.cellNumber.length
                        ) && isFormDirty
                      "
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-text-field
                      v-model="editedItem.email"
                      required
                      label="Email *"
                      :error="
                        !(editedItem.email && editedItem.email.length) &&
                          isFormDirty
                      "
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                  >
                    <v-text-field
                      v-model="editedItem.studentId"
                      required
                      label="Custom Info"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                  >
                    <v-select
                      v-model="selectedGroup"
                      :items="groups"
                      label="Group *"
                      item-text="name"
                      item-value="id"
                      required
                      :error="!selectedGroup && isFormDirty"
                    />
                  </v-col>
                </v-row>
              </v-container>
              <small>* indicates required field</small>
            </v-card-text>

            <v-card-actions>
              <v-spacer />
              <v-btn
                color="secondary"
                text
                @click="close"
              >
                Cancel
              </v-btn>
              <v-btn
                color="primary"
                text
                @click="save"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>

    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="contacts"
      item-key="localId"
      show-select
      :options.sync="options"
      sort-by="firstName"
      :server-items-length="maxOptions"
      :loading="loading"
      :footer-props="{
        itemsPerPageOptions: [500, 100, 1000,1500,2000],
      }"
      @item-expanded="_loadContactMessages"
    >
      <template v-slot:item.actions="{ item }">
        <div class="senderActions">
          <v-icon
            small
            @click="_showMessagingDialog(item)"
          >
            mdi-message-draw
          </v-icon>
          <v-icon
            small
            class="ml-4"
            @click="_showContactProfileDialog(item)"
          >
            mdi-account
          </v-icon>
          <v-btn
            color="primary"
            outlined
            x-small
            class="ml-4"
            @click="showAddToGroupsDialog(item)"
          >
            Add to groups
          </v-btn>
          <v-btn
            color="primary"
            outlined
            x-small
            class="ml-4"
            @click="showEditModal(item)"
          >
            Edit
          </v-btn>
          <v-icon
            small
            @click="deleteContact(item)"
          >
            mdi-delete
          </v-icon>
        </div>
      </template>
      <template v-slot:item.groupName="{ item }">
        <div v-if="item.groups && item.groups.length">
          <div
            v-if="item.groups.length === 1"
            class="singleGroup"
          >
            {{ item.groups[0].groupName }}
          </div>
          <div
            v-if="item.groups.length > 1"
            class="singleGroup"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <div
                  v-bind="attrs"
                  v-on="on"
                >
                  In {{ item.groups.length }} Groups
                  <v-icon
                    color="primary"
                    dark
                  >
                    mdi-eye
                  </v-icon>
                </div>
              </template>
              <div
                v-for="(group, groupIndex) in item.groups"
                :key="groupIndex"
              >
                {{ group.groupName }}
              </div>
            </v-tooltip>
          </div>
        </div>
      </template>
      <template v-slot:item.firstName="{ item }">
        <span
          class="cellLink"
          @click="_showContactProfileDialog(item)"
        >
          {{ item.firstName }}
        </span>
      </template>
      <template v-slot:item.lastName="{ item }">
        <span
          class="cellLink"
          @click="_showContactProfileDialog(item)"
        >
          {{ item.lastName }}
        </span>
      </template>
      <template v-slot:no-data>
        <p class="mb-4 mt-4">
          No contacts to display
        </p>
        <v-btn
          color="primary"
          outlined
          class="mb-4"
          @click="_loadData"
        >
          Refresh
        </v-btn>
      </template>
    </v-data-table>
    <hubspot-import-dialog
      v-if="isHubspotImportEnabled"
      :is-visible="isHubspotDialogVisible"
      :groups="groups"
      :hubspot-token="hubspotToken"
      :username="username"
      @on-success="onHubspotContactsImportSuccess"
      @on-cancel="onHubspotContactsImportCancel"
      @on-error="onHubspotContactsImportError"
    />
    <v-snackbar v-model="snackbar">
      {{ notification }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <div class="py-3" />
    <add-contact-to-groups-dialog
      :is-visible="isAddContactToGroupsDialogVisible"
      :groups="groups"
      :contact="selectedContactToAddInGroups"
      :all-contacts="contacts"
      @on-submit="onAddToGroupsSubmit"
      @on-close="hideAddToGroupsDialog"
      @on-success="onAddToGroupsSuccess"
      @on-error="onAddToGroupsError"
    />
  </v-container>
</template>

<script>
  import HttpConnector from '../../../utils/http-connector'
  import { VueMaskDirective } from 'v-mask'
  import { mapState } from 'vuex'
  import vueDebounce from 'vue-debounce'
  import Vue from 'vue'
  import cuid from 'cuid'
  import moment from 'moment'
  import EventEmitter from '../../../utils/event-emitter'
  Vue.directive('mask', VueMaskDirective)
  Vue.use(vueDebounce)

  // Listening to multiple events
  Vue.use(vueDebounce, {
    listenTo: ['input', 'keyup'],
  })

  // Setting a default timer This is set to '300ms' if not specified
  Vue.use(vueDebounce, {
    defaultTime: '700ms',
  })

  export default {
    components: {
      ImportContactsDialog: () => import('../components/ImportContactsDialog'),
      HubspotImportDialog: () => import('../components/HubspotImportDialog'),
      GoogleContactsImportDialog: () =>
      import('../components/GoogleContactsImportDialog'),
      AddContactToGroupsDialog: () =>
      import('../components/AddContactToGroupsDialog'),
    },
    data: () => ({
      httpConnector: new HttpConnector(),
      apiEndpoint: process.env.VUE_APP_API_BASE_URL,
      apiCode: process.env.VUE_APP_API_CODE,
      moment,
      dialog: false,
      headers: [
        { text: 'First Name', value: 'firstName' },
        { text: 'Last Name', value: 'lastName' },
        { text: 'Cell Number', value: 'cellNumber' },
        { text: 'Email', value: 'email' },
        { text: 'Custom Info', value: 'studentID' },
        { text: 'Group', value: 'groupName' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      snackbar: false,
      notification: 'Unknown',
      isAddContactToGroupsDialogVisible: false,
      selectedGroup: 0,
      selected: [],
      contacts: [],
      groups: [],
      searchText: '',
      filteredContacts: [],
      editedIndex: -1,
      selectedContactToAddInGroups: null,
      editedItem: {
        id: -1,
        firstName: '',
        lastName: '',
        cellNumber: '',
        groupId: '',
        studentId: '',
        email: '',
      },
      defaultItem: {
        id: -1,
        firstName: '',
        email: '',
        lastName: '',
        cellNumber: '',
        groupId: '',
      },
      isOverlayVisible: false,
      isFormDirty: false,
      isHubspotDialogVisible: false,
      hubspotToken: '',
      isHubspotImportEnabled: false,
      isGoogleImportEnabled: false,
      loading: false,
      options: {},
      maxOptions: 0,
      selectedStatus: 0,
      isAccordianDataLoading: false,
      accordianContactMessageData: null,
    }),

    computed: {
      ...mapState(['appAddOns']),
      formTitle () {
        return this.editedIndex === -1 ? 'New Contact' : 'Edit Contact'
      },
    },

    watch: {
      options: {
        handler (props) {
          this._loadData(props)
        },
        deep: true,
      },
      dialog (val) {
        val || this.close()
      },
      appAddOns (val) {
        console.log('appAddOnsval', val)
        this._setPermissionsFromAddons(val)
      },
    },

    async created () {
      this.username = localStorage.getItem('username')
      await this.initialize()
    },

    methods: {
      _setPermissionsFromAddons (val) {
        if (val && val.length) {
          const hubSpotAddOn = val.find(addOn => addOn.AppAddOnID === 1)
          if (hubSpotAddOn) {
            this.isHubspotImportEnabled = !!hubSpotAddOn.isEnabled
          }
          const googleAddOn = val.find(addOn => addOn.AppAddOnID === 2)
          if (googleAddOn) {
            this.isGoogleImportEnabled = !!googleAddOn.isEnabled
          }
        }
      },
      async _loadData (props = null) {
        this._setPermissionsFromAddons(this.$store.state.appAddOns)
        this.loading = true
        const searchText = this.searchText.trim().toLowerCase()
        let payload = {}
        if (props && props.page) {
          payload = {
            offset: props.page,
            searchterm: searchText,
            perPage: props.itemsPerPage,
          }
          if (props.sortBy && props.sortBy.length) {
            payload.sortBy = this._formatSortProperty(props.sortBy[0])
            payload.isAsc = props.sortDesc[0]
          }
        } else {
          payload = {
            offset: 1,
            searchterm: searchText,
            perPage: 10,
          }
        }
        try {
          await this._loadGroups()
          const response = await this._makePaginatedApiCall(payload)
          if (response && response.data && response.data.data) {
            this.contacts = this._formatContacts(response.data.data)
            this.maxOptions = response.data.totalCount
          }
        } catch (error) {
          console.log('error :>> ', error)
        }
        this.loading = false
      },
      async _loadGroups () {
        try {
          if (!(this.groups && this.groups.length)) {
            const groupResponse = await this.httpConnector.makeEncryptedRequest(
              'get',
              `${this.apiEndpoint}groups?code=${this.apiCode}`,
            )
            this.groups = []
            groupResponse.forEach(group => {
              this.groups.push({
                id: group.groupID,
                name: group.name,
              })
            })
          }
        } catch (error) {
          console.log('error :>> ', error)
        }
      },
      _formatContacts (contacts) {
        const formattedContacts = contacts.map(contact => ({
          id: contact.ContactID,
          firstName: contact.FirstName,
          lastName: contact.LastName,
          cellNumber: contact.CellPhone,
          studentID: contact.StudentID,
          // group: contact.Name,
          groupId: contact.GroupID,
          email: contact.Email && contact.Email.length ? contact.Email : '',
          // groupName: contact.Name,
          localId: cuid(),
          messageSent: contact.Message,
          responseRecieved: contact.Response,
        }))
        return this._mergeContactsByCellNumber(formattedContacts)
      },
      async _makePaginatedApiCall (payload) {
        return await this.httpConnector.makeRequest(
          'post',
          `${this.apiEndpoint}contacts/info?code=${this.apiCode}`,
          payload,
        )
      },

      _mergeContactsByCellNumber (unmergedContacts) {
        const cellNumberMappedContacts = {}
        const idMappedGroups = {}
        this.groups.forEach(group => {
          idMappedGroups[group.id] = group
        })
        unmergedContacts.forEach((unmergedContact) => {
          if (
            unmergedContact.cellNumber &&
            unmergedContact.cellNumber.toString().length
          ) {
            if (!cellNumberMappedContacts[unmergedContact.cellNumber]) {
              cellNumberMappedContacts[
                unmergedContact.cellNumber
              ] = Object.assign({}, unmergedContact)
              if (cellNumberMappedContacts[unmergedContact.cellNumber].groupId) {
                delete cellNumberMappedContacts[unmergedContact.cellNumber]
                  .groupId
              }
              cellNumberMappedContacts[unmergedContact.cellNumber].groups = []
            }
            if (unmergedContact.groupId && idMappedGroups[unmergedContact.groupId]) {
              cellNumberMappedContacts[unmergedContact.cellNumber].groups.push({
                groupId: unmergedContact.groupId,
                groupName: idMappedGroups[unmergedContact.groupId].name,
              })
            }
          }
        })
        // Convert the object into an array
        const mergedContacts = []
        Object.values(cellNumberMappedContacts).forEach(mergedContact => {
          mergedContacts.push({
            ...mergedContact,
            localId: cuid(),
          })
        })
        return mergedContacts
      },

      _showMessagingDialog (contact) {
        EventEmitter.dispatch('show-messaging-popup', contact)
      },

      _showContactProfileDialog (contact) {
        const formattedContact = {
          ...contact,
        }
        if (contact.groups && contact.groups.length) {
          formattedContact.groupId = contact.groups[0].groupId
        } else {
          formattedContact.groupId = 0
        }
        EventEmitter.dispatch('show-contact-profile-popup', formattedContact)
      },

      /**
       * Add some formatting to the selected sortby option
       * (As per backend requirement)
       */
      _formatSortProperty (sortBy) {
        let formattedSortProperty = sortBy
        switch (sortBy) {
          case 'messageSent': {
            formattedSortProperty = 'datesent'
            break
          }
          case 'responseRecieved': {
            formattedSortProperty = 'responsedate'
            break
          }
          default: {
            formattedSortProperty = sortBy.toLowerCase()
          }
        }
        return formattedSortProperty
      },

      async initialize () {
        try {
          this.isOverlayVisible = true
          // Combine multiple calls to minimise the network call time
          const groupResponse = await this.httpConnector.makeEncryptedRequest(
            'get',
            `${this.apiEndpoint}groups?code=${this.apiCode}`,
          )
          this.groups = []
          groupResponse.forEach(group => {
            this.groups.push({
              id: group.groupID,
              name: group.name,
            })
          })
          await this.checkForHubspotImportToken()
          this.isOverlayVisible = false
          this.close()
        } catch (error) {
          // console.log('error :>> ', error)
          this.isOverlayVisible = false
        }
      },

      async deleteContact (contact) {
        if (confirm('Are you sure you want to remove this contact?')) {
          this.isOverlayVisible = true
          this.httpConnector
            .makeRequest(
              'delete',
              `${this.apiEndpoint}contacts/${contact.id}?code=${this.apiCode}`,
            )
            .then(async res => {
              this.notification = 'Contact has been removed'
              this.snackbar = true
              // Remove the contact from the local list
              const contactIndex = this.contacts.findIndex(
                c => c.id === contact.id,
              )
              if (contactIndex > -1) {
                this.contacts.splice(contactIndex, 1)
              }
              this.filterContacts()
              this.isOverlayVisible = false
            })
            .catch(error => {
              this.notification = 'Unable to remove the contact'
              this.snackbar = true
              this.isOverlayVisible = false
              throw error
            })
        }
      },

      async checkForHubspotImportToken () {
        // Check if code parameter is available
        const queryString = window.location.search
        const codeParts = queryString.split('?code=')
        if (codeParts && codeParts.length === 2) {
          const code = codeParts[1]
          // Code available
          // Initiate hubspot import
          this.hubspotToken = code
          this.isHubspotDialogVisible = true
        }
      },

      navigateToGroupView (e) {
        this.$router.push('/pages/groups/' + e.groupId + '/contacts')
      },

      importStart () {
        this.isOverlayVisible = true
      },

      async importSuccess (res) {
        await this._loadData()
        this.isOverlayVisible = false
        this.notification = 'Contacts have been imported'
        this.snackbar = true
      },

      importError (error) {
        this.isOverlayVisible = false
        this.notification = 'Unable to import contacts '
        this.snackbar = true

        throw error
      },

      onHubspotContactsImportCancel () {
        this.isHubspotDialogVisible = false
      },

      onGoogleContactsImportStart () {
        this.isOverlayVisible = true
      },

      async onGoogleContactsImportSuccess (res) {
        await this._loadData()
        this.isOverlayVisible = false
        this.notification = 'Contacts have been imported'
        this.snackbar = true
      },

      onGoogleContactsImportError () {
        this.isOverlayVisible = false
      },

      async onHubspotContactsImportSuccess (res) {
        this.isHubspotDialogVisible = false
        await this._loadData()
        this.isOverlayVisible = false
        this.notification = 'Contacts have been imported'
        this.snackbar = true
      },

      onHubspotContactsImportError () {
        this.isOverlayVisible = false
        this.notification = 'Server error'
        this.snackbar = true
        this.isHubspotDialogVisible = false
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = {
            id: -1,
            firstName: '',
            lastName: '',
            cellNumber: '',
            groupId: '',
            studentId: '',
          }
          this.editedIndex = -1
          this.selectedGroup = 0
          this.isFormDirty = false
        })
      },

      isFormValid () {
        let isValid = true
        this.notification = ''
        if (!this.editedItem.firstName || !this.editedItem.firstName.length) {
          this.notification = 'Fill all the required fields'
          isValid = false
        }
        if (!this.editedItem.lastName || !this.editedItem.lastName.length) {
          this.notification = 'Fill all the required fields'
          isValid = false
        }
        if (
          !this.editedItem.cellNumber ||
          !this.editedItem.cellNumber.length ||
          this.parseCellNumberFromMaskFormat(this.editedItem.cellNumber)
            .length !== 10
        ) {
          this.notification = 'Provide a valid 10 digit cell number'
          isValid = false
        }
        if (!this.selectedGroup) {
          this.notification = 'Fill all the required fields'
          isValid = false
        }
        return isValid
      },

      parseCellNumberFromMaskFormat (cellNumber) {
        return cellNumber.replace(/[^0-9,]+/g, '')
      },

      startHubSpotAuthentication () {
        window.location =
          'https://app.hubspot.com/oauth/authorize?client_id=ceab9d7c-86c3-4d19-bad0-38cbfe90374f&redirect_uri=https://letsconnect.azurewebsites.net/pages/contacts&scope=contacts'
      },

      save () {
        this.isFormDirty = true
        if (!this.isFormValid()) {
          this.snackbar = true
          return
        }

        this.isOverlayVisible = true
        const form = {
          FirstName: this.editedItem.firstName,
          LastName: this.editedItem.lastName,
          CellPhone: this.editedItem.cellNumber.replace(/[^0-9]+/g, ''),
          UserID: this.username,
          GroupID: this.selectedGroup,
          Email: this.editedItem.email,
        }

        if (this.editedIndex > -1) {
          form.StudentID = this.editedItem.studentID
          this.httpConnector
            .makeRequest(
              'put',
              `${this.apiEndpoint}contacts/${this.editedItem.id}?code=${this.apiCode}`,
              form,
            )
            .then(async res => {
              this.notification = 'Contact has been updated'
              this.snackbar = true
              this.close()
              await this._loadData()
              this.isOverlayVisible = false
            })
            .catch(error => {
              this.notification = 'Unable to update the contact'
              this.snackbar = true
              this.isOverlayVisible = false
              throw error
            })
        } else {
          if (this.editedItem.studentId && this.editedItem.studentId.length) {
            form.StudentID = this.editedItem.studentId
          }
          this.httpConnector
            .makeRequest(
              'post',
              `${this.apiEndpoint}contacts?code=${this.apiCode}`,
              form,
            )
            .then(async res => {
              this.notification = 'Contact has been created'
              this.snackbar = true
              this.close()
              await this._loadData()
              this.isOverlayVisible = false
            })
            .catch(error => {
              this.notification = 'Unable to create the contact'
              this.snackbar = true
              this.isOverlayVisible = false
              throw error
            })
        }
      },

      showAddToGroupsDialog (contact) {
        this.selectedContactToAddInGroups = contact
        this.isAddContactToGroupsDialogVisible = true
      },

      hideAddToGroupsDialog () {
        this.selectedContactToAddInGroups = null
        this.isAddContactToGroupsDialogVisible = false
      },

      onAddToGroupsSubmit () {
        this.isOverlayVisible = true
      },

      async onAddToGroupsSuccess () {
        await this._loadData()
        this.hideAddToGroupsDialog()
        this.notification = 'Contact added to groups'
        this.isOverlayVisible = false
        this.snackbar = true
        this.loading = false
      },

      showEditModal (item) {
        console.log('item :>> ', item)
        this.editedIndex = this.contacts.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.selectedGroup = item.GroupID
        if (item.groups && item.groups.length) {
          this.selectedGroup = item.groups[0].groupId
        }
        this.dialog = true
      },

      onAddToGroupsError () {
        this.isOverlayVisible = false
        this.hideAddToGroupsDialog()
        this.notification = 'Error adding contact to groups'
        this.snackbar = true
      },

      async _loadContactMessages ({ item: contact, value: isOpen }) {
        this.accordianContactMessageData = null
        if (isOpen) {
          this.isAccordianDataLoading = true
          const { data } = await this.httpConnector.makeRequest(
            'get',
            `${this.apiEndpoint}contacts/${contact.id}/info?code=${this.apiCode}`,
          )
          this.accordianContactMessageData = data
          this.isAccordianDataLoading = false
        }
      },
    },
  }
</script>
<style lang="scss" scoped>
.v-overlay.v-overlay--active.theme--dark {
  z-index: 300 !important;
}
.spinner {
  margin-left: 17rem;
}
@media only screen and (max-width: 968px) {
  .spinner {
    margin-left: 0;
  }
}
.loaderWrapper {
  .loader,
  .loader:after {
    border-radius: 50%;
    width: 5em;
    height: 5em;
  }
  .loader {
    margin: 10px 0 15px 10px;
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: 0.5em solid rgba(240, 164, 0, 0.2);
    border-right: 0.5em solid rgba(240, 164, 0, 0.2);
    border-bottom: 0.5em solid rgba(240, 164, 0, 0.2);
    border-left: 0.5em solid #f0a400;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
  }
  @-webkit-keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
}

.accordianWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 14rem;
  .accordianInnerWrapper {
    width: 70vw;
    border: 1px solid black;
    border-radius: 5px;
    min-height: 10rem;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    .part {
      width: 50%;
      min-height: 10rem;
      .messageHeader {
        // height: 28px;
        width: 100%;
        padding: 4px 10px;
        background-color: black;
        color: white;
        font-size: 14px;
        height: 30px;
        font-weight: 600;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .dateTime {
          margin-left: 15px;
          background-color: rgb(0, 58, 192);
          border-radius: 20px;
          color: white;
          font-size: 0.8rem;
          font-weight: 600;
          padding: 1px 10px;
        }
      }
      .messageBody {
        width: 100%;
        padding: 4px 10px;
        // background-color: black;
        color: black;
        font-size: 14px;
        height: calc(12rem - 30px);
        font-weight: 600;
      }
      &.leftpart {
        .messageHeader {
          border-right: 1px solid white;
        }
        .messageBody {
          border-right: 1px solid black;
        }
      }
    }
  }
  .message {
    .label {
      font-weight: 600;
    }
  }
}
.cellLink {
  text-decoration: underline;
  color: rgb(0, 65, 161);
  cursor: pointer;
}
</style>
