var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"my-contacts","fluid":"","tag":"section"}},[_c('v-overlay',{attrs:{"value":_vm.isOverlayVisible}},[_c('v-progress-circular',{staticClass:"spinner",attrs:{"size":70,"width":7,"color":"amber","indeterminate":""}})],1),_c('base-v-component',{attrs:{"heading":"Contacts","sub-heading":"Contact List"}}),[_c('v-toolbar',{attrs:{"flat":"","color":"transparent"}},[_c('v-text-field',{directives:[{name:"debounce",rawName:"v-debounce:300ms",value:(_vm._loadData),expression:"_loadData",arg:"300ms"}],staticClass:"searchBox",attrs:{"label":"Search"},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}}),_c('v-spacer'),(_vm.isHubspotImportEnabled)?_c('v-btn',{staticClass:"mb-2 mr-8",attrs:{"color":"primary","outlined":"","dark":""},on:{"click":_vm.startHubSpotAuthentication}},[_vm._v(" Import Hubspot Contacts ")]):_vm._e(),(_vm.isGoogleImportEnabled)?_c('google-contacts-import-dialog',{attrs:{"groups":_vm.groups},on:{"on-upload-start":_vm.onGoogleContactsImportStart,"on-success":_vm.onGoogleContactsImportSuccess,"on-error":_vm.onGoogleContactsImportError}}):_vm._e(),_c('import-contacts-dialog',{attrs:{"groups":_vm.groups},on:{"on-upload-start":_vm.importStart,"on-success":_vm.importSuccess,"on-error":_vm.importError}}),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2 ml-4",attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Add Contact ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"required":"","label":"First Name *","error":!(
                        _vm.editedItem.firstName && _vm.editedItem.firstName.length
                      ) && _vm.isFormDirty},model:{value:(_vm.editedItem.firstName),callback:function ($$v) {_vm.$set(_vm.editedItem, "firstName", $$v)},expression:"editedItem.firstName"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"required":"","label":"Last Name *","error":!(_vm.editedItem.lastName && _vm.editedItem.lastName.length) &&
                        _vm.isFormDirty},model:{value:(_vm.editedItem.lastName),callback:function ($$v) {_vm.$set(_vm.editedItem, "lastName", $$v)},expression:"editedItem.lastName"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('(###) ###-####'),expression:"'(###) ###-####'"}],attrs:{"placeholder":"(___) ___-____","required":"","label":"Cell Number *","error":!(
                        _vm.editedItem.cellNumber && _vm.editedItem.cellNumber.length
                      ) && _vm.isFormDirty},model:{value:(_vm.editedItem.cellNumber),callback:function ($$v) {_vm.$set(_vm.editedItem, "cellNumber", $$v)},expression:"editedItem.cellNumber"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"required":"","label":"Email *","error":!(_vm.editedItem.email && _vm.editedItem.email.length) &&
                        _vm.isFormDirty},model:{value:(_vm.editedItem.email),callback:function ($$v) {_vm.$set(_vm.editedItem, "email", $$v)},expression:"editedItem.email"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-text-field',{attrs:{"required":"","label":"Custom Info"},model:{value:(_vm.editedItem.studentId),callback:function ($$v) {_vm.$set(_vm.editedItem, "studentId", $$v)},expression:"editedItem.studentId"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-select',{attrs:{"items":_vm.groups,"label":"Group *","item-text":"name","item-value":"id","required":"","error":!_vm.selectedGroup && _vm.isFormDirty},model:{value:(_vm.selectedGroup),callback:function ($$v) {_vm.selectedGroup=$$v},expression:"selectedGroup"}})],1)],1)],1),_c('small',[_vm._v("* indicates required field")])],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"secondary","text":""},on:{"click":_vm.close}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.save}},[_vm._v(" Save ")])],1)],1)],1)],1)],_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.contacts,"item-key":"localId","show-select":"","options":_vm.options,"sort-by":"firstName","server-items-length":_vm.maxOptions,"loading":_vm.loading,"footer-props":{
      itemsPerPageOptions: [500, 100, 1000,1500,2000],
    }},on:{"update:options":function($event){_vm.options=$event},"item-expanded":_vm._loadContactMessages},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"senderActions"},[_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm._showMessagingDialog(item)}}},[_vm._v(" mdi-message-draw ")]),_c('v-icon',{staticClass:"ml-4",attrs:{"small":""},on:{"click":function($event){return _vm._showContactProfileDialog(item)}}},[_vm._v(" mdi-account ")]),_c('v-btn',{staticClass:"ml-4",attrs:{"color":"primary","outlined":"","x-small":""},on:{"click":function($event){return _vm.showAddToGroupsDialog(item)}}},[_vm._v(" Add to groups ")]),_c('v-btn',{staticClass:"ml-4",attrs:{"color":"primary","outlined":"","x-small":""},on:{"click":function($event){return _vm.showEditModal(item)}}},[_vm._v(" Edit ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteContact(item)}}},[_vm._v(" mdi-delete ")])],1)]}},{key:"item.groupName",fn:function(ref){
    var item = ref.item;
return [(item.groups && item.groups.length)?_c('div',[(item.groups.length === 1)?_c('div',{staticClass:"singleGroup"},[_vm._v(" "+_vm._s(item.groups[0].groupName)+" ")]):_vm._e(),(item.groups.length > 1)?_c('div',{staticClass:"singleGroup"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_vm._v(" In "+_vm._s(item.groups.length)+" Groups "),_c('v-icon',{attrs:{"color":"primary","dark":""}},[_vm._v(" mdi-eye ")])],1)]}}],null,true)},_vm._l((item.groups),function(group,groupIndex){return _c('div',{key:groupIndex},[_vm._v(" "+_vm._s(group.groupName)+" ")])}),0)],1):_vm._e()]):_vm._e()]}},{key:"item.firstName",fn:function(ref){
    var item = ref.item;
return [_c('span',{staticClass:"cellLink",on:{"click":function($event){return _vm._showContactProfileDialog(item)}}},[_vm._v(" "+_vm._s(item.firstName)+" ")])]}},{key:"item.lastName",fn:function(ref){
    var item = ref.item;
return [_c('span',{staticClass:"cellLink",on:{"click":function($event){return _vm._showContactProfileDialog(item)}}},[_vm._v(" "+_vm._s(item.lastName)+" ")])]}},{key:"no-data",fn:function(){return [_c('p',{staticClass:"mb-4 mt-4"},[_vm._v(" No contacts to display ")]),_c('v-btn',{staticClass:"mb-4",attrs:{"color":"primary","outlined":""},on:{"click":_vm._loadData}},[_vm._v(" Refresh ")])]},proxy:true}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),(_vm.isHubspotImportEnabled)?_c('hubspot-import-dialog',{attrs:{"is-visible":_vm.isHubspotDialogVisible,"groups":_vm.groups,"hubspot-token":_vm.hubspotToken,"username":_vm.username},on:{"on-success":_vm.onHubspotContactsImportSuccess,"on-cancel":_vm.onHubspotContactsImportCancel,"on-error":_vm.onHubspotContactsImportError}}):_vm._e(),_c('v-snackbar',{scopedSlots:_vm._u([{key:"action",fn:function(ref){
    var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"pink","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.notification)+" ")]),_c('div',{staticClass:"py-3"}),_c('add-contact-to-groups-dialog',{attrs:{"is-visible":_vm.isAddContactToGroupsDialogVisible,"groups":_vm.groups,"contact":_vm.selectedContactToAddInGroups,"all-contacts":_vm.contacts},on:{"on-submit":_vm.onAddToGroupsSubmit,"on-close":_vm.hideAddToGroupsDialog,"on-success":_vm.onAddToGroupsSuccess,"on-error":_vm.onAddToGroupsError}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }